#sideBar .info-title-element {
    font-weight: 800;
    padding: 2px;
}

#sideBar .info-value-element {
    font-weight: normal;
}

#sideBar .center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
}
