@tailwind base;

/**
 * Begin global overrides.
 *
 * You can use `@apply` or `theme()` to access existing Tailwind CSS styles or variables.
 * (See https://tailwindcss.com/docs/adding-base-styles/#using-css)
 */

body {
	color: theme('textColor.gray.800');
}

/* End global overrides. */

@tailwind components;
@tailwind utilities;

.app-container {
	max-width: 80%;
	margin: auto;
}

.lrn-btn.lrn-btn-primary.styled-preview-button {
	margin-right: 10px;
	padding: 3px 0px !important;
	border-radius: 4px !important;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
	margin-bottom: 10px;
	width: 32px;
	height: 32px;
	min-width: 32px;
	min-height: 32px;
}
