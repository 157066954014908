#sideBar .lrn-author-cross-container {
	display: none;
}

#sideBar .lrn-btn.lrn-author-btn.lrn-btn-cancel {
	display: none;
}

#sideBar .lrn-author-item-settings-header {
	display: none;
}

.lrn-author-tab-link {
	display: flex;
    align-items: center;
    justify-content: center;
	.k-icon-style {
		top: 3px;
		position: relative;
		font-size: 16.5px;
	}
}